import { FC } from "react";
import { SvgIconProps } from "@mui/material/SvgIcon";

import AlarmIcon from "@mui/icons-material/Alarm";
import LightIcon from "@mui/icons-material/Light";
import AcUnitIcon from "@mui/icons-material/AcUnit";
import AirIcon from "@mui/icons-material/Air";
import ViewAgendaIcon from "@mui/icons-material/ViewAgenda";
import PowerIcon from "@mui/icons-material/Power";
import ThermostatIcon from "@mui/icons-material/Thermostat";
import BlindsIcon from "@mui/icons-material/Blinds";
import LocalConvenienceStoreIcon from "@mui/icons-material/LocalConvenienceStore";
import DriveEtaIcon from "@mui/icons-material/DriveEta";
import LocalFireDepartmentIcon from "@mui/icons-material/LocalFireDepartment";
import RoofingIcon from "@mui/icons-material/Roofing";
import WarehouseIcon from "@mui/icons-material/Warehouse";

const IconSet = {
  alarm: AlarmIcon,
  light: LightIcon,
  "light in unit": LightIcon,
  "climate controlled": AcUnitIcon,
  "humidity controlled": AirIcon,
  "stacked space": ViewAgendaIcon,
  "power outlet": PowerIcon,
  "air cooled": ThermostatIcon,
  uncovered: BlindsIcon,
  "24 hour access": LocalConvenienceStoreIcon,
  "drive-up access": DriveEtaIcon,
  heated: LocalFireDepartmentIcon,
  covered: RoofingIcon,
};

export type Icons = keyof typeof IconSet;

export const Icon: FC<{ iconName: Icons | string } & SvgIconProps> = ({
  iconName,
  ...props
}) => {
  const IconComponent = Object.keys(IconSet).includes(iconName)
    ? IconSet[iconName as Icons]
    : WarehouseIcon;
  return <IconComponent color="inherit" fontSize="inherit" sx={{mr:0.5}} {...props}  />;
};
