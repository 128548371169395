import { FC, useMemo, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import TuneIcon from "@mui/icons-material/Tune";

import {
  IUnitsInformation,
  UnitsInformation,
} from "../components/UnitsInformation";
// import { mockUnitsDetials } from "../mock/mockUnitsData";
import { useUnitInformation } from "../context/UnitsInformationProvider";
import { createUnitsGroup } from "../components/UnitsInformation/type";
import { FilterInformation } from "../components/FilterInformation";
import { useConfig } from "../context/ConfigProvider";

// const buttons = [
//   { name: "All", onClick: () => {}, active: true },
//   { name: "Small", onClick: () => {}, active: false },
//   { name: "Medium", onClick: () => {}, active: false },
//   { name: "Large", onClick: () => {}, active: false },
// ];

interface IUnitsDisplayProps {
  onSelectedUnit: (unitId: IUnitsInformation) => void;
}

export const UnitsDisplay: FC<IUnitsDisplayProps> = (props) => {
  const [showFilers, setShowFilters] = useState<boolean>(false);
  const {
    unitCategories,
    selectCategory,
    selectedUnitCategory,
    readyToDisplayUnitGroups,
    filters,
    priceRange,
    priceRangePossible,
    isLoadingUnitGroups,
  } = useUnitInformation();
  const { configuration } = useConfig();

  const filtersCount = useMemo(() => {
    let count = filters.length;
    if (
      priceRange[0] !== priceRangePossible[0] ||
      priceRange[1] !== priceRangePossible[1]
    ) {
      count++;
    }
    return count;
  }, [filters, priceRange, priceRangePossible]);
  if (isLoadingUnitGroups) {
    return (
      <Box width="100%" alignContent="center">
        <CircularProgress
          size={100}
          sx={{ display: "block", margin: "auto" }}
        />
      </Box>
    );
  }
  return (
    <Box width="100%">
      <Box
        sx={(theme) => ({
          display: "flex",
          flexDirection: theme.direction === "ltr" ? "row" : "row-reverse",
          mb: 1,
        })}
      >
        <Box
          sx={(theme) => ({
            display: "flex",
            flex: 1,
            flexDirection: theme.direction === "ltr" ? "row" : "row-reverse",
            flexWrap: theme.direction === "ltr" ? "wrap" : "wrap-reverse",
          })}
        >
          <Button
            onClick={() => selectCategory(undefined)}
            size="large"
            variant={selectedUnitCategory === undefined ? "contained" : "text"}
            sx={(theme) => (theme.direction === "ltr" ? { mr: 1 } : { ml: 1 })}
          >
            All
          </Button>
          {unitCategories.map((category, index) => (
            <Button
              key={index}
              onClick={() => selectCategory(category)}
              size="large"
              variant={
                category.id === selectedUnitCategory?.id ? "contained" : "text"
              }
              sx={(theme) =>
                theme.direction === "ltr" ? { mr: 1 } : { ml: 1 }
              }
            >
              {category.name}
            </Button>
          ))}
        </Box>
        <Button
          size="small"
          sx={{ borderRadius: 28, maxHeight: 36 }}
          variant={showFilers ? "contained" : "outlined"}
          onClick={() => setShowFilters((curr) => !curr)}
        >
          {`Filters${filtersCount === 0 ? "" : "(" + filtersCount + ")"} `}
          <TuneIcon fontSize="small" sx={{ ml: 0.5 }} />
        </Button>
      </Box>
      <FilterInformation open={showFilers} />
      {readyToDisplayUnitGroups.length ? (
        (configuration.showUnavailableUnits
          ? readyToDisplayUnitGroups
          : readyToDisplayUnitGroups.filter((x) => x.availableUnitsCount > 0)
        ).map((unit, index) => (
          <UnitsInformation
            key={index}
            {...createUnitsGroup(unit, configuration.showUnavailableUnits)}
            onSelectClick={() => {
              props.onSelectedUnit(createUnitsGroup(unit));
            }}
          />
        ))
      ) : (
        <Typography variant="h4" textAlign="center">
          {filters.length > 0
            ? "Sorry, no units match your search. Try clearing some filter options to show more results."
            : "We're currently experiencing difficulties retrieving available units. To check availability, please contact the facility directly."}
        </Typography>
      )}
    </Box>
  );
};
