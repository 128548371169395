import { Image, UnitGroup, UnitGroupTier } from "../../types/UnitGroup";
import { getImageSet } from "./subComponents/Image";
import { SortData } from "../../utils/Sort";

export type IUnitImageInformation = {
  images?: Image | Image[];
  preserveImageArea?: boolean;
};

export type IUnitsPriceAvailabilityInformation = {
  startPrice: number;
  endPrice?: number;
  availbleUnits: number;
  pricePer: string;
  discountedPriceStart?: number;
  discountedPriceEnd?: number;
  currencySymbol: string;
  fees: UnitGroup["applicableFees"];
  totalApplicableFees: number;
};

export type IUnitAmenitiesInformation = {
  amenities: string[];
  noOfAmenitiesToShow?: number;
};

export interface IUnitsInformation
  extends IUnitImageInformation,
    IUnitsPriceAvailabilityInformation,
    IUnitAmenitiesInformation {
  id: string;
  name?: string;
  size: { length: number; width: number; height: number };
  category?: string;
  unitType?: string;
  tiers: UnitGroupTier[];
  discountPlans: UnitGroup["discountPlans"];
  location: string;
  onSelectClick?: () => void;
}

export const createUnitsGroup = (
  unitGroup: UnitGroup,
  showLowestBasedOnAvailableTier = false
): IUnitsInformation => {
  const lowestTier = SortData(
    unitGroup.unitGroupTiers || [],
    (x) => x.discountedPrice || x.price,
    "asc"
  ).find((x) => x.availableUnitsCount > 0);
  
  return {
    id: unitGroup.id,
    name: unitGroup.name,
    size: {
      width: unitGroup.width,
      length: unitGroup.length,
      height: unitGroup.height || 0,
    },
    startPrice: (showLowestBasedOnAvailableTier
      ? unitGroup
      : lowestTier || unitGroup
    ).price,
    discountedPriceStart: (showLowestBasedOnAvailableTier
      ? unitGroup
      : lowestTier || unitGroup
    ).discountedPrice,
    totalApplicableFees: unitGroup.totalApplicableFees,
    fees: unitGroup.applicableFees || [],
    // endPrice: unitGroup.discountedPrice,
    availbleUnits: unitGroup.availableUnitsCount,
    pricePer: "mo",
    currencySymbol: "$",
    amenities: unitGroup.amenities.map((amenity) => amenity.name),
    images: getImageSet({
      size: {
        length: unitGroup.length,
        width: unitGroup.width,
        height: unitGroup.height || 0,
      },
      category: unitGroup?.unitCategories?.[0]?.name,
      unitType: unitGroup?.unitType,
      amenities: unitGroup.amenities.map((amenity) => amenity.name),
    } as IUnitsInformation),
    tiers: unitGroup.unitGroupTiers || [],
    preserveImageArea: true,
    category: unitGroup.unitCategories?.[0]?.name,
    unitType: unitGroup?.unitType,
    discountPlans: unitGroup.discountPlans || [],
    location: [
      unitGroup.accessType,
      unitGroup.floor,
      unitGroup.unitEnclosureStatus,
    ]
      .filter((x) => Boolean(x))
      .join(", "),
  };
};
