import { FC } from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import moment from "moment";
import { IReservationRequest, IReservationResponse } from "../../services";
import { IReservationForm } from "./type";
import { InformationPair } from "../../components/InformationPair";
import { useConfig } from "../../context/ConfigProvider";
import { DATE_FORMAT } from "../../constants";

interface IReservationInformation {
  typeOfForm: IReservationForm["typeOfForm"];
  reservationInformation: IReservationResponse;
  reservationRequest: IReservationRequest;
  onMoveInClick: () => void;
  onFindAnotherUnitClick: () => void;
}

export const ReservationInformation: FC<IReservationInformation> = (props) => {
  const { configuration } = useConfig();
  return (
    <>
      <Typography variant="h5" gutterBottom>
        {props.typeOfForm === "contactUs"
          ? "Message Sent"
          : "Reservation Confirmed"}
      </Typography>

      {props.typeOfForm !== "contactUs" && (
        <Typography>
          {configuration.reservationConfirmationText.replace(
            "{{reservationId}}",
            props.reservationInformation.id
          )}
        </Typography>
      )}

      <InformationPair
        data={
          props.typeOfForm === "contactUs"
            ? [
                {
                  label: "Name",
                  value: [
                    props.reservationRequest.firstName,
                    props.reservationRequest.lastName,
                  ].join(" "),
                },
                {
                  label: "Email",
                  value: props.reservationRequest.emailAddress,
                },
                {
                  label: "Phone Number",
                  value: props.reservationRequest.phoneNumber,
                },
                {
                  label: "Message",
                  value: props.reservationRequest.notes || "",
                },
              ]
            : [
                {
                  label: "Name",
                  value: [
                    props.reservationRequest.firstName,
                    props.reservationRequest.lastName,
                  ].join(" "),
                },
                {
                  label: "Move-in Date",
                  value: moment(
                    props.reservationRequest.desiredMoveInDate!
                  ).format(DATE_FORMAT),
                },
              ]
        }
      />
      {props.typeOfForm !== "contactUs" ? (
        <>
          {(configuration.forceMoveIn || configuration.enableMoveIn) && ( <Button
            fullWidth
            variant="contained"
            sx={{ my: 1 }}
            onClick={props.onMoveInClick}
          >
            Finish Your Move-In
          </Button> )}
          <Button
            fullWidth
            variant="text"
            onClick={props.onFindAnotherUnitClick}
          >
            Find another unit
          </Button>
        </>
      ) : (
        <Button
          fullWidth
          variant="contained"
          sx={{ my: 1 }}
          onClick={props.onFindAnotherUnitClick}
        >
          Find another unit
        </Button>
      )}
    </>
  );
};
