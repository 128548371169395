import { UnitGroup } from "../types/UnitGroup";
import { Get } from "../utils/Api";

export const GetUnitGroups = (
  facilityId: string,
  source: string,
  includeTiers: boolean = true
) =>
  Get<UnitGroup[]>(
    `/v1/facilities/${facilityId}/unit-groups${includeTiers ? "?includeTiers=true" : ""
    }${source ? `${includeTiers ? "&" : "?"}source=${source}` : ""}`
  );

export const GetUnitGroupSummary = (
  facilityId: string,
  selectedGroupId: string
): Promise<UnitGroup> =>
  Get<UnitGroup[]>(
    `/v1/facilities/${facilityId}/unit-groups/${selectedGroupId}/units-summary`
  ).then((unitGroups) => unitGroups.find(unit => (unit as any)?.availableForMoveIn) || unitGroups[0]);


