import { FC, PropsWithChildren } from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import InfoIcon from "@mui/icons-material/Info";

import { IUnitsPriceAvailabilityInformation } from "../type";
import { useConfig } from "../../../context/ConfigProvider";
  
const getProperValue = (value: number) =>
  value % 1 === 0 ? value : value.toFixed(2);

const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "transparent",
    padding: 0,
    margin: 0,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.background.paper,
  },
}));

const ExtraInfo: FC<IUnitsPriceAvailabilityInformation> = (props) => {
  return (
    <CustomTooltip
      arrow
      // open
      slotProps={{
        popper: {
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, -14],
              },
            },
          ],
        },
      }}
      title={
        <Card sx={{ p: 1 }} elevation={5}>
          <Typography variant="caption">
            The Following fees shall apply:
            <Box sx={{ pl: 2 }}>
              <ul style={{ padding: 0, margin: 0 }}>
                {(props.fees || []).map((fee, index) => (
                  <li key={index}>{`${fee.description} ${
                    fee.description.endsWith("Fee") ? "" : "Fee"
                  }: $${getProperValue(fee.fee)}${
                    fee.frequency === "monthly"
                      ? "/mo"
                      : " (" + fee.frequency + ")"
                  }`}</li>
                ))}
              </ul>
            </Box>
          </Typography>
        </Card>
      }
    >
      <InfoIcon sx={(theme) => ({ color: theme.palette.grey[500] })} />
    </CustomTooltip>
  );
};

export const Pricing: FC<
  PropsWithChildren<IUnitsPriceAvailabilityInformation>
> = (props) => {
  const { configuration } = useConfig();
  switch (true) {
    // Show discounted price if it is defined and less than startPrice
    case props.discountedPriceStart !== null &&
      props.discountedPriceStart !== undefined &&
      props.discountedPriceStart < props.startPrice:
      return (
        <Box>
          <Box display="flex" flexDirection="row" alignItems="center">
            <Typography
              variant="caption"
              color="GrayText"
              sx={{ textDecoration: "line-through", mr: 0.5 }}
            >
              {`${props.currencySymbol}${props.startPrice}/${props.pricePer}`}
            </Typography>
            <Typography
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-end",
              }}
              variant="subtitle1"
            >
              <Typography
                variant="h6"
                // sx={{ fontWeight: "bold" }}
              >{`${props.currencySymbol}${props.discountedPriceStart}`}</Typography>
              {`/${props.pricePer}`}
            </Typography>

            {props.children}
          </Box>
          {configuration.showFeeDetails && (
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              sx={{ justifyContent: { xs: "flex-start", lg: "flex-end" } }}
            >
              <Typography variant="caption">
                {`+ $${getProperValue(props.totalApplicableFees)} in fees`}
              </Typography>
              <ExtraInfo {...props} />
            </Box>
          )}
        </Box>
      );
    // its a range .. so we show Starts from
    case Boolean(props.startPrice) && Boolean(props.endPrice):
      return (
        <Box>
          <Box display="flex" flexDirection="row" alignItems="center">
            <Typography variant="caption" color="GrayText" sx={{ mr: 0.5 }}>
              Starts at
            </Typography>
            <Typography
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-end",
              }}
              variant="subtitle1"
            >
              <Typography
                variant="h6"
                // sx={{ fontWeight: "bold" }}
              >{`${props.currencySymbol}${props.startPrice}`}</Typography>
              {`/${props.pricePer}`}
            </Typography>
            {props.children}
          </Box>
          {configuration.showFeeDetails && (
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              sx={{ justifyContent: { xs: "flex-start", lg: "flex-end" } }}
            >
              <Typography variant="caption">
                {`+ $${getProperValue(props.totalApplicableFees)} in fees`}
              </Typography>
              <ExtraInfo {...props} />
            </Box>
          )}
        </Box>
      );
    default:
      return (
        <Box>
          <Box display="flex" flexDirection="row" alignItems="center">
            <Typography
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-end",
              }}
              variant="subtitle1"
            >
              <Typography
                variant="h6"
                // sx={{ fontWeight: "bold" }}
              >{`${props.currencySymbol}${props.startPrice}`}</Typography>
              {`/${props.pricePer}`}
            </Typography>
            {props.children}
          </Box>
          {configuration.showFeeDetails && (
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              sx={{ justifyContent: { xs: "flex-start", lg: "flex-end" } }}
            >
              <Typography variant="caption">
                {`+ ${getProperValue(props.totalApplicableFees)} in fees`}
              </Typography>
              <ExtraInfo {...props} />
            </Box>
          )}
        </Box>
      );
  }
};
