import { IWidgetParams } from "../types/WidgetParams";

export const widgetParams: IWidgetParams = {
  providerId: 'se',
  source: "Voyager",
  // CUG
  // facilityId: "cbe11a81-db7f-4ddb-8da0-44f09d095ba2",
  // organizationId: "a0756a77-5ab9-4c50-b715-fd5a68030d77",

  // different discount plans
  // facilityId: "e0f8e3e4-82f0-43aa-b997-3a9269ea79cc",
  // organizationId: "60f040b3-fbcd-4c2d-892f-62673b3ec7ef",

  facilityId:"b609da9c-3f6e-4999-b51b-0690891d7454",
  organizationId: "5ac68ae4-0ac1-418a-af7b-98bc192406f3",
  //krishna
  // facilityId: 'e1af3904-7595-483a-aebe-98dc7a7e9dca',
  // organizationId: '5ac68ae4-0ac1-418a-af7b-98bc192406f3',

  // facilityId: '60582537-ed81-4bc1-93b6-8f581cc3efc2',
  // organizationId: 'bdce07df-b54a-4b0a-8488-0cc3c304c28f',
  // providerId: 'se',

  // facilityId: "e1af3904-7595-483a-aebe-98dc7a7e9dca",
  // organizationId: "5ac68ae4-0ac1-418a-af7b-98bc192406f3",
};

// export default widgetParams;
