import { FC } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import { IUnitAmenitiesInformation } from "../type";
import { Icon } from "../../Icon";

export const Amenities: FC<IUnitAmenitiesInformation> = (props) => {
  const allAmenities = props.amenities.slice(
    0,
    props.noOfAmenitiesToShow || props.amenities.length
  );
  return (
    <Box
      sx={(theme) => ({
        display: "flex",
        flexDirection: theme.direction === "ltr" ? "row" : "row-reverse",
        flexWrap: theme.direction === "ltr" ? "wrap" : "wrap-reverse",
      })}
    >
      {allAmenities.map((amenity, index) => (
        <Box
          component={Typography}
          key={index}
          display="flex"
          flexDirection="row"
          alignItems="center"
          mr={1.5}
          mb={1}
          sx={(theme) => ({
            fontSize: theme.typography.pxToRem(14),
            color: "#686868",
          })}
        >
          <Icon iconName={amenity.toLowerCase()} sx={{ mr: 1 }} />
          {amenity}
        </Box>
      ))}
    </Box>
  );
};
