export interface IUTConfiguration {
  omiUrl: string;
  omiPassMode: "parameter" | "query";

  enableMoveIn: boolean;
  forceMoveIn: boolean;

  showUnitCount: boolean;
  showUnavailableUnits: boolean;
  contactForReserveUnitThreshold: number;

  reservationDisclaimerText: string;
  reservationConfirmationText: string;

  showFeeDetails: boolean;

  themification: {
    colors: {
      primary: string;
      secondary: string;
      tertiary: string;
    };
    fontFamily: string;
    button: {
      borderRadius: number;
      textStyle: string;
    };
  };
}

export const defaultUTConfiguration: IUTConfiguration = {
  omiUrl: "http://localhost:3000",
  omiPassMode: "parameter",

  enableMoveIn: true,
  forceMoveIn: false,

  showUnitCount: true,
  showUnavailableUnits: true,
  contactForReserveUnitThreshold: 1,

  showFeeDetails: false,

  reservationDisclaimerText:
    "By clicking on the Reserve button, you agree to the terms and conditions of the property",
  reservationConfirmationText:
    "Your Reservation has been confirmed and the Id for the same is {{reservationId}}",

  themification: {
    colors: {
      primary: "#337AB7",
      secondary: "#F48400",
      tertiary: "#000000",
    },
    fontFamily: "Montserrat",
    button: {
      borderRadius: 1,
      textStyle: "capitalize",
    },
  },
};
