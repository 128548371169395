import { FC } from "react";
import Box from "@mui/material/Box";
import { IUnitsInformation } from "../type";
import { Image } from "../../../types/UnitGroup";

const possibleUnitGroupSet = {
  under5x5: { min: 0, max: 24 },
  "5x5": { min: 25, max: 25 },
  "5x10": { min: 26, max: 50 },
  "10x10": { min: 51, max: 100 },
  "10x15": { min: 101, max: 150 },
  "10x20": { min: 151, max: 200 },
  "10x25": { min: 201, max: 250 },
  "10x30": { min: 251, max: -1 },
};

export const getImageSet = (unitInformation: IUnitsInformation): Image[] => {
  const { size, category, amenities = [], unitType } = unitInformation;

  const normalizedAmenities = amenities.map(a => a.toLowerCase());

  if (unitType){
    normalizedAmenities.push(unitType?.toLowerCase());
  }

  const isVehicleOrParking =
    category?.toLowerCase() === 'vehicle' ||
    normalizedAmenities.some(amenity =>
      ['parking', 'rv', 'boat', 'vehicle'].some(keyword => amenity.includes(keyword))
    );

  if (isVehicleOrParking) {
    const area = (size?.length ?? 0) * (size?.width ?? 0);
    const type = area < 80 ? 'small' : 'medium';

    const isCovered = normalizedAmenities.some(amenity => {
      const normalizedAmenity = amenity.toLowerCase().trim();
      return (
        normalizedAmenity.includes('covered') &&
        !normalizedAmenity.includes('uncovered') &&
        !normalizedAmenity.includes('not covered')
      );
    });
        
    const isRV = normalizedAmenities.some(amenity => amenity.includes('rv'));
    const isBoat = normalizedAmenities.some(amenity => amenity.includes('boat'));

    const keyParts = ['parking'];
    if (isBoat) keyParts.push('boat');
    if (isRV) keyParts.push('rv');
    if (!isBoat && !isRV) keyParts.push(type);
    if (isCovered && !isBoat) keyParts.push('covered');

    const key = keyParts.join('-');

    const imageCounts: Record<string, number> = {
      'parking-rv-covered': 1,
      'parking-rv': 2,
      'parking-boat': 1,
      'parking-boat-rv': 2,
      'parking-small-covered': 1,
      'parking-small': 3,
      'parking-medium-covered': 1,
      'parking-medium': 1,
    };

    const imageCount = imageCounts[key] ?? 1;

    return Array.from({ length: imageCount }, (_, i) => ({
      url: `${import.meta.env.VITE_IMAGES_URL || "https://unitstable-widget.webapps.dev.rnl.io"
        }/${key}-${i + 1}.png`,
      altName: `Parking ${isCovered ? 'Covered ' : ''}${isRV ? 'RV ' : isBoat ? 'Boat ' : ''}${type} Unit Image ${i + 1}`,
      description: `Parking ${isCovered ? 'Covered ' : ''}${isRV ? 'RV ' : isBoat ? 'Boat ' : ''}${type} Unit Image ${i + 1}`,
    }));
  }

  let unitGroup = Object.entries(possibleUnitGroupSet).find(([, setData]) => {
    const { min, max } = setData;
    const area = size.length * size.width;
    return area >= min && area <= max;
  });
  if (!unitGroup) {
    unitGroup = ["10x30", { min: 251, max: -1 }];
  }
  return (unitGroup[0] === "under5x5" ? [1, 2, 3] : [1, 2, 3, 4]).map(
    (image) => ({
      url: `${import.meta.env.VITE_IMAGES_URL ||
        "https://unitstable-widget.webapps.dev.rnl.io"
        }/${unitGroup[0]}-${image}.png`,
      altName: `${unitGroup[0]} Unit Group Image`,
      description: `${unitGroup[0]} Unit Group Image`,
    })
  );
};

interface IUnitsImage {
  unitInformation: IUnitsInformation;
  // preserveImageArea?: boolean;
  forceCarousel?: boolean;
  heightFactor?: number;
  widthFactor?: number;
}

export const UnitsImage: FC<IUnitsImage> = ({
  unitInformation,
  heightFactor = 9,
  widthFactor = 12,
  // forceCarousel = false,
}) => {
  const { images, preserveImageArea } = unitInformation;
  const finalImages = Array.isArray(images) ? images : [images];

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "center",
      }}
    >
      {finalImages && finalImages.length > 0 ? (
        <Box
          sx={(theme) => ({
            height: theme.spacing(heightFactor),
            width: theme.spacing(widthFactor),
          })}
        >
          <img
            src={finalImages[0]?.url || "https://placehold.co/150"}
            style={{ height: "100%", width: "100%", objectFit: "contain" }}
            alt={finalImages[0]?.altName || "Unit Group Image"}
          />
        </Box>
      ) : preserveImageArea ? (
        <Box
          sx={(theme) => ({
            height: theme.spacing(heightFactor),
            width: theme.spacing(widthFactor),
          })}
        />
      ) : null}
    </Box>
  );
};
